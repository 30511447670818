.App {
  text-align: center;
  font-family: 'DM Sans', sans-serif;
}

:root {
  --mainBlue: #2D4BF3;
  --mainBlack: #333333;
  --mainPurple: #9D3CCB;
}

.selected {
  color: #ff0000;
}